<div
  class="tw-flex tw-flex-col xl:!tw-flex-row tw-justify-between tw-mb-8 tw-p-8 tw-border-x-0 tw-border-t-0 tw-border-b-1 tw-border-solid tw-border-gray-200">
  <div class="tw-w-full tw-max-w-1/2 tw-flex tw-flex-row tw-items-center tw-justify-center xl:!tw-justify-start">
    <!-- Title/Name Input - Only show if title is provided -->
    @if (title !== undefined) {
      <stretchy-input 
        [(ngModel)]="title" 
        class="tw-w-full">
      </stretchy-input>
    }

    <!-- More Options Menu -->
    @if (actions?.moreOptions?.length > 0 || actions?.deleteAction) {
    <button id="moreSettingsButton" type="button"
      class="dropdown-toggle tw-flex tw-items-center tw-justify-center tw-ml-2 tw-rounded-full tw-p-2 tw-border tw-border-solid tw-border-gray-200 tw-bg-transparent focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
      [matMenuTriggerFor]="menu" aria-label="More options">
      <mat-icon svgIcon="more-vertical" class="tw-rotate-90" />
    </button>
    <mat-menu #menu="matMenu">
      @for (action of actions.moreOptions; track action.id) {
      @if (!action.disabled) {
      <button mat-menu-item [id]="action.id" *requireRole="action.requireRole"
        (click)="action.action()" (keyup.enter)="action.action()"
        [class.rise-admin-only]="action.requireRole === 'sa'">
        {{action.label}}
      </button>
      }
      }
      @if (actions.deleteAction && !actions.deleteAction.disabled) {
      <button mat-menu-item [id]="actions.deleteAction.id"
        *requireRole="actions.deleteAction.requireRole"
        [class.rise-admin-only]="actions.deleteAction.requireRole === 'sa'"
        (click)="actions.deleteAction.action()">
        {{actions.deleteAction.label}}
      </button>
      }
    </mat-menu>
    }
  </div>

  <div class="tw-flex tw-flex-col xl:!tw-flex-row tw-gap-4 tw-items-center tw-justify-center xl:!tw-justify-end tw-w-full tw-max-w-1/2">
    <p class="tw-text-center xl:!tw-text-right tw-w-full tw-m-0">
      @if (changeDate || changedBy) {
        <last-modified [changeDate]="changeDate" [changedBy]="changedBy">
        </last-modified>
      }
    </p>

    @if (actions?.cancelAction) {
    <button [id]="actions.cancelAction.id" type="button" 
      [disabled]="actions.cancelAction.disabled"
      (click)="actions.cancelAction.action()"
      class="tw-w-full tw-max-w-36" mat-stroked-button>
      {{actions.cancelAction.label}}
    </button>
    }

    @if (actions?.saveAction) {
    <button [id]="actions.saveAction.id" type="submit" 
      [disabled]="actions.saveAction.disabled || saving"
      class="tw-w-full tw-max-w-52" 
      *requireRole="actions.saveAction.requireRole" mat-flat-button color="primary">
      {{ saving ? 'Saving' : actions.saveAction.label}}
    </button>
    }
  </div>
</div>